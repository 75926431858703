/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./Footer"
// import GlobalStyle from "../themes/GlobalStyle"

const Layout = ({ children, className, flexDown }) => {
  return (
    <>
      <Header />
      <div className={"main-content-wrapper"}>
        <main className={flexDown}>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
