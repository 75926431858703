import { useIntl } from "react-intl"
export const localized = field => {
  const intl = useIntl()
  if (!intl) {
    return
  }
  if (!field) {
    return
  }
  if (!field[intl.locale]) {
    if (intl.locale === "vi") {
      return field && field["en"]
    } else {
      return field && field["vi"]
    }
  }
  return field && field[intl.locale]
}
