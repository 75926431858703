import { Link } from "gatsby-plugin-intl"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes, css } from "styled-components"
import { useIntl } from "react-intl"
import { fadeInDown, fadeOut } from "react-animations"
import { localized } from "../utils/localized"
import Logo from "../images/icons/coderschool-header.svg"
import Language from "./Language"

const fadeInDownAnimation = keyframes`${fadeInDown}`
const fadeOutUpAnimation = keyframes`${fadeOut}`

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()

  const headers = useStaticQuery(graphql`
    query CourseAndHeaderLinkedPages {
      sanityNavbar {
        _id
        navbarContent {
          __typename
          ... on SanityHeaderGroup {
            _key
            _type
            name {
              en
              vi
            }
            pageList {
              _id
              viTitle
              title
              slug {
                current
              }
            }
          }
          ... on SanityPage {
            _type
            _id
            viTitle
            title
            slug {
              current
            }
          }
          ... on SanityWeblink {
            _type
            _key
            name {
              en
              vi
            }
            url
          }
        }
      }
    }
  `).sanityNavbar.navbarContent

  const parsed = headers.map(item => {
    if (item._type === "headerGroup") {
      return (
        <li className="nav-v3__item" key={item._key}>
          <div className="header-adaptive js-accordion" data-animation="on">
            <div className="js-accordion__item">
              <button
                className="header-adaptive__header d-flex align-items-center-impt accordion__header js-tab-focus js-accordion__trigger"
                type="button"
                aria-expanded="false"
                aria-controls="accordion-content-0"
                id="accordion-header-0"
                style={{ outline: "none" }}
              >
                <span className="nav-v3__link">{localized(item.name)}</span>
                <svg
                  className="svg-inline--fa fa-chevron-down fa-w-14 icon icon--xs margin-left-xxs"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-down"
                  role="img"
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg
                >
                  <path
                    fill="currentColor"
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                  />
                </svg>
              </button>
              <ul
                className="header-adaptive__dropdown header-adaptive__dropdown--center js-accordion__panel"
                aria-labelledby="accordion-header-0"
                id="accordion-content-0"
              >
                {item.pageList.map(n => (
                  <li key={n._id}>
                    <Link
                      to={`/${n.slug.current}`}
                      className="header-adaptive__dropdownLink"
                    >
                      {localized({ en: n.title, vi: n.viTitle })}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      )
    }
    if (item._type === "page")
      return (
        <li className="nav-v3__item" key={item._id}>
          <Link
            to={`/${item.slug.current}`}
            className="header-adaptive__dropdownLink"
          >
            {localized({ vi: item.viTitle, en: item.title })}
          </Link>
        </li>
      )
    if (item._type === "weblink")
      return (
        <li className="nav-v3__item" key={item._key}>
          <a href={item.url} className="header-adaptive__dropdownLink">
            {localized(item.name)}
          </a>
        </li>
      )
    return ""
  })

  const ResponsiveMenu = ({ isOpen, mobileButtonArray }) => {
    const intl = useIntl()

    return (
      <FullscreenMenu isOpen={isOpen}>
        {mobileButtonArray}
        <Link
          to="/apply-now"
          className="btn btn--subtle width-100% margin-y-md"
        >
          {intl.formatMessage({ id: "apply_now" })}
        </Link>
        <Language />
      </FullscreenMenu>
    )
  }

  const mobileButtonArray = headers.map((item, index) => {
    if (item._type === "headerGroup") {
      return (
        <React.Fragment key={item._key}>
          <BoldText>{localized(item.name)}</BoldText>
          {item.pageList.map(n => (
            <Link
              to={`/${n.slug.current}`}
              className="header-adaptive__dropdownLink"
              key={n._id}
            >
              {localized({ en: n.title, vi: n.viTitle })}
            </Link>
          ))}
        </React.Fragment>
      )
    }
    if (item._type === "page") {
      return (
        <Link
          to={`/${item.slug.current}`}
          className="header-adaptive__dropdownLink margin-top-lg"
          key={item._id}
        >
          {localized({ en: item.title, vi: item.viTitle })}
        </Link>
      )
    }
    if (item._type === "weblink")
      return (
        <a
          href={item.url}
          className="header-adaptive__dropdownLink margin-top-lg"
        >
          {localized(item.name)}
        </a>
      )
    return ""
  })

  const showMenu = () => setIsOpen(!isOpen)

  return (
    <header className="section header-v3" style={{ position: "sticky" }}>
      <div className="section__inner">
        <div className="header-v3__container">
          <div className="header-v3__logo header-v3__logo--mobile  hide@lg">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <button
            onClick={showMenu}
            className="reset header-v3__nav-control switch-icon switch-icon--slide-down js"
            aria-label="Toggle menu"
            style={{ outline: "none" }}
          >
            <svg
              className="switch-icon__icon switch-icon__icon--a"
              width={32}
              height={14}
              viewBox="0 0 32 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={32} height={3} fill="#D64541" />
              <rect y={11} width={32} height={3} fill="#D64541" />
            </svg>
          </button>
          <nav className="nav-v3" role="navigation">
            <ul className="nav-v3__list">
              <li className="nav-v3__item nav-v3__item--logo flex-shrink-0">
                <div className="header-v3__logo" data-theme="default">
                  <Link to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                </div>
              </li>
              {parsed}
              <li className="nav-v3__item has-no-border">
                <Link to="/apply-now" className="btn btn--subtle ">
                  {intl.formatMessage({ id: "apply_now" })}
                </Link>
              </li>
              <Language />
            </ul>
          </nav>
        </div>
      </div>
      <ResponsiveMenu isOpen={isOpen} mobileButtonArray={mobileButtonArray} />
    </header>
  )
}
const Icon = styled.i`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`

const FullscreenMenu = styled.div`
  animation: ${props =>
    (props.isOpen === true &&
      css`
        ${fadeInDownAnimation} 0.5s
      `) ||
    (props.isOpen === false &&
      css`
        ${fadeOutUpAnimation} 2s
      `)};
  background: white;
  padding: 0 2.5rem;

  width: 100vw;
  height: 100vh;
  background: white;
  position: absolute;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  .Collapsible__trigger {
    display: block;
    font-size: 1.25rem;
    border-bottom: 1px solid lightgray;
  }
  .Collapsible__trigger.is-closed {
    cursor: pointer;
  }
  .Collapsible__trigger.is-open {
    color: red;
    cursor: pointer;

    transition: background 300ms ease-in-out 0s !important;
    & ${Icon} {
      color: red !important;
    }
  }
  .Collapsible__contentInner {
    border-top-color: transparent;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .Collapsible__contentOuter {
    transition: height 300ms ease-in-out 0s !important;
    margin: 0;
  }
  margin-top: -4px;
`

const BoldText = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  padding: 1.5rem 0;
`

export default Header
