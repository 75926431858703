import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import styled from "styled-components"
import { useEffect } from "react"

const languageName = {
  vi: "VI",
  en: "EN",
}

// Harcoded to return li's, sorry folks.

// TODO Who the fuck wrote this? Why is it hardcoded 0 and 1, wtf?!

export default ({ classNames }) => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <Languages>
          <li className={classNames} style={{ listStyleType: "none" }}>
            <a
              key={languages[0]}
              onClick={() => changeLocale(languages[0])}
              style={{
                color: currentLocale === languages[0] ? `black` : `lightgray`,
                cursor: `pointer`,
              }}
            >
              {languageName[languages[0]]}
            </a>
          </li>
          <InlineDivider />
          <li className={classNames} style={{ listStyleType: "none" }}>
            <a
              key={languages[1]}
              onClick={() => changeLocale(languages[1])}
              style={{
                color: currentLocale === languages[1] ? `black` : `lightgray`,
                cursor: `pointer`,
              }}
            >
              {languageName[languages[1]]}
            </a>
          </li>
        </Languages>
      )}
    </IntlContextConsumer>
  )
}

const Languages = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  margin-right: 2rem;
  margin-left: 2rem;
`

const InlineDivider = styled.div`
  width: 2px;
  height: 24px;
  background-color: lightgray;
  margin-bottom: -2px;
`
