import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"
import { Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faCompass,
  faPhoneSquare,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

import {
  faYoutube,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

export default () => {
  const intl = useIntl()

  const coursePages = useStaticQuery(graphql`
    {
      allSanityPage(filter: { pageType: { eq: "course" } }) {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `).allSanityPage.nodes.filter(item => item.slug !== null)
  return (
    <footer className="section main-footer">
      <div className="section__inner padding-y-xl">
        <div className="main-footer__content">
          <nav className="main-footer__nav">
            <ul className="main-footer__nav-list">
              <li className="main-footer__nav-item">
                <h4>Contact</h4>
                <div>
                  <a href="https://goo.gl/maps/agFTtCU85dodxggY6">
                    <FontAwesomeIcon
                      icon={faCompass}
                      className="icon icon--sm margin-right-xxs"
                    />
                    {intl.formatMessage({ id: "address" })}
                  </a>
                </div>
                <div>
                  <a href="tel:+0984326569">
                    <FontAwesomeIcon
                      icon={faPhoneSquare}
                      className="icon icon--sm margin-right-xxs"
                    />
                    0984 326 569
                  </a>
                </div>
                <div>
                  <a href="mailto:apply@coderschool.vn">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon icon--sm margin-right-xxs"
                    />
                    apply@coderschool.vn
                  </a>
                </div>
                <div className="main-footer__nav-item margin-top-lg">
                  <h4>Follow us</h4>
                  <div className="socials">
                    <ul className="socials__btns flex flex-gap-sm flex-wrap">
                      <li>
                        <a
                          href="https://www.youtube.com/c/CoderSchool"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faYoutube} className="icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.facebook.com/coderschoolvn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebook} className="icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.linkedin.com/school/coderschool"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faLinkedin} className="icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="main-footer__nav-item">
                <div className="main-footer__nav-item margin-top-lg">
                  <h4>{intl.formatMessage({ id: "about_us" })}</h4>
                  <Link to="/about-us">About CoderSchool</Link>
                  <a href="https://talent.coderschool.vn/">
                    Work at CoderSchool
                  </a>
                  <Link to="/blog">Blog</Link>
                </div>
                {/* <div className="main-footer__nav-item margin-top-lg">
                  <h4>For Companies</h4>
                  <Link to="/partnership">
                    {intl.formatMessage({ id: "hire_graduates" })}
                  </Link>
                </div> */}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="main-footer__colophon">
        <div className="main-footer__colophon-nav">
          <span>
            CoderSchool {new Date().getFullYear()} All Rights Reserved. By a big
            team:&nbsp;
          </span>
          Conceptual Studio (CoderSchool alumni!), Ian Tran (CoderSchool
          alumnus!), Vinh Duong, and Charles Lee (CoderSchool founder can still
          code....slowly)
        </div>
      </div>
    </footer>
  )
}
